<template>
  <div>
    <title-bar :title-stack="titleStack"/>
    <section class="section is-main-section">
<!--      <card-component content-class="p-0">-->
<!--        <sg-tab v-model="activeTab"-->
<!--                :items="['Metrics', 'Chart']"-->
<!--                class="px-4 border-top-0"/>-->
<!--      </card-component>-->

      <book-metrics/>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleBar from '@/components/TitleBar'
import BookMetrics from "@/components/Book/BookMetrics";
// import BookChart from "@/components/Book/BookChart";
// import SgTab from "@/components/Sg/SgTab";
// import CardComponent from "@/components/CardComponent";

export default {
  name: 'BookOverview',
  components: {
    // CardComponent,
    // SgTab,
    // BookChart,
    BookMetrics,
    TitleBar
  },
  data: () => {
    return {
      activeTab: 'Metrics',
    }
  },
  computed: {
    titleStack() {
      return ['SgaraBook', 'Overview']
    }
  },
}
</script>
